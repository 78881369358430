<template lang="pug">
.game-currencies
  SectionHeader(:header="header")

  .game-currencies__list
    .game-currencies__element(v-for="appOffer in appOffers" :key="appOffer.id")
      p
        | Продавец (ID =&nbsp;
        b.game-currencies__element-game {{ appOffer.account_id }}
        | )&nbsp; просит добавить {{ appType(appOffer.app_type) }} "{{ appOffer.name }}"
      Button(is-primary @click="complete(appOffer.id)") Удалить
</template>

<script>
import SectionHeader from '@/components/SectionHeader/SectionHeader';

export default {
  name: "AppOffers",

  components: {
    SectionHeader
  },

  data () {
    return {
      appOffers: []
    }
  },

  computed: {
    header() {
      return {
        title: 'Заявки на добавление игр и приложений в маркетплейс',
      };
    },
  },

  mounted() {
    this.loadAppOffers()
  },

  methods: {
    appType (type) {
      switch (type) {
        case 'game':
          return 'игру'
        case 'mobile_game':
          return 'мобильную игру'
        case 'social':
          return 'соц. сеть'
        case 'app':
          return 'приложение'
        default:
          return type
      }
    },

    async loadAppOffers() {
      this.$loading.start()

      try {
        const { data } = await this.$axios.get('/marketplace/apps/offers')

        this.appOffers = data.data
      } catch (e) {
        this.$error.handle(e)
      }

      this.$loading.finish()
    },

    async complete(appOfferId) {
      try {
        await this.$axios.post(`/marketplace/app/offer/${appOfferId}/close`)
      } catch (e) {
        this.$error.handle(e)
      }
      this.loadAppOffers()
    }
  }
}
</script>

<style scoped lang="scss">
.game-currencies {
  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    padding: 8px 24px;
  }

  &__element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background: #141e2b;
    border-radius: 8px;
    color: white;

    &-game {
      color: #48b050;
    }

    &-product {
      color: #bf0012;
      font-weight: 900;
      font-size: 20px;
    }
  }
}
</style>